export function ProductSlider(itemClass, namespace){
    // console.log(itemClass, namespace);
    return{
        itemClass: itemClass ?? '.ProductSlider-item',
        namespace: namespace ?? 'product-box',
        currentItem: null,
        items: [],
        isDown: false,
        startX:null,
        scrollLeft:null,
        dragged: false,
        get perPage(){ return getComputedStyle(this.$root).getPropertyValue('--per-page') },
        get pages(){ return Math.ceil(this.items.length / this.perPage) },

        init(){
            // console.log(this.$root);
                this.items = Array.from(this.$root.querySelectorAll(this.itemClass)).map(el => (el));
                this.findCurrentItem();
                this.$nextTick(()=>this.findCurrentItem())
                
        },

        goto(page){
            let item = (this.perPage * page) - (this.perPage - 1);
            this.$refs.list.scrollTo({left: document.getElementById(this.namespace+'-'+item).offsetLeft, behavior: 'smooth'})
        },

        findCurrentItem(){
            // console.log('findCurrentItem', this.currentItem);
            if( this.currentItem == null ){
                // console.log('setting current item')
                this.currentItem = this.items[0].id;
            } 
            
            this.currentItem =  this.items.reduce((prev, curr) => {
                return ( Math.abs(curr.getBoundingClientRect().left ) < Math.abs(prev.getBoundingClientRect().left) ? curr : prev ); 
            }).id;
        },

        mousedown(e){
            this.isDown = true;
      
            
            // this.$refs.list.classList.add('is-active');
            this.startX = e.pageX - this.$refs.list.offsetLeft;
            this.scrollLeft = this.$refs.list.scrollLeft;
        },

        mouseleave(){
            this.isDown = false;
            
        },

        mouseup(e){
            // console.log('start mouseup');
            if(!this.isDown){
                // console.log(e);
                //window.location.href = 
            }
            this.isDown = false;
            setTimeout(() => {
                this.dragged = false;
            }, 250);
            // console.log('end mouseup',this.dragged);
            
        },
        
        mousemove(e){
            if (!this.isDown) return;
            e.preventDefault();
            const x = e.pageX - this.$refs.list.offsetLeft;
            const walk = (x - this.startX) * 1; //increase to scroll-fast
            this.$refs.list.scrollLeft = this.scrollLeft - walk;
            this.dragged = true;
            // console.log(walk);
        }
    }
}



const slider = document.querySelector('.Events ol');

